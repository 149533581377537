import { createSlice } from "@reduxjs/toolkit";

const EventSlice = createSlice({
  name: "events",
  initialState: {
    events: [],
    event: {},
    eventCategories: [],
  },
  reducers: {
    setEvents: (state, action) => {
      state.events = action.payload;
    },
    setEvent: (state, action) => {
      state.event = action.payload;
    },
    setEventCategories: (state, action) => {
      state.eventCategories = action.payload;
    },
  },
});

export const { setEvents, setEvent, setEventCategories } = EventSlice.actions;

export default EventSlice.reducer;
