import { createSlice } from "@reduxjs/toolkit";

const PlaygroundSlice = createSlice({
  name: "playgrounds",
  initialState: {
    playgrounds: [],
    playground: {},
    playground_tiers: [],
    tier_seats: [],
  },
  reducers: {
    setPlaygrounds: (state, action) => {
      state.playgrounds = action.payload;
    },
    setPlayground: (state, action) => {
      state.playground = action.payload;
    },
    setPlaygroundTiers: (state, action) => {
      state.playground_tiers = action.payload;
    },
    setTierSeats: (state, action) => {
      state.tier_seats = action.payload;
    },
  },
});

export const {
  setPlaygrounds,
  setPlayground,
  setPlaygroundTiers,
  setTierSeats,
} = PlaygroundSlice.actions;

export default PlaygroundSlice.reducer;
