import { createSlice } from "@reduxjs/toolkit";

const CategorySlice = createSlice({
  name: "categories",
  initialState: {
    categories: [],
    category: {},
  },
  reducers: {
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
    setCategory: (state, action) => {
      state.category = action.payload;
    },
  },
});

export const { setCategories, setCategory } = CategorySlice.actions;

export default CategorySlice.reducer;
