import { lazy } from "react";
import WithLazyComponent from "../shared/components/WithLazyComponent";
import VerifyCardContainer from "./VerifyCardByGuard/containers/VerifyCardContainer";

const HomeIndex = WithLazyComponent(lazy(() => import("./Home/index")));
const LogIndex = WithLazyComponent(lazy(() => import("./Register/index")));
const SignIndex = WithLazyComponent(lazy(() => import("./Register/index")));
const ProfileIndex = WithLazyComponent(lazy(() => import("./Profile/index")));
const EventIndex = WithLazyComponent(lazy(() => import("./Event/index")));
const CarttIndex = WithLazyComponent(lazy(() => import("./Cart/index")));
const PaymentIndex = WithLazyComponent(lazy(() => import("./Payment/index")));
const OrdersIndex = WithLazyComponent(lazy(() => import("./Orders/index")));
const VerifyCardIndex = WithLazyComponent(lazy(() => import("./Verify/index")));
const VerifyCardPpgesIndex = WithLazyComponent(lazy(() => import("./VerifyCardByGuard/index")));
const ForgetPassword = WithLazyComponent(
  lazy(() => import("./Register/index"))
);

export const SiteRoutes = [
  {
    path: "/home",
    // element: <Layout />,
    children: [
      { index: true, element: HomeIndex },
      //   { path: 'create', element: Create },
    ],
  },
  {
    path: "/paymentstatus/:paymentstatus",
    element: HomeIndex,
  },
  {
    path: "/login",
    element: LogIndex,
  },
  {
    path: "/signup",
    element: SignIndex,
  },
  {
    path: "/profile",
    element: ProfileIndex,
  },
  { path: "/forget-password", element: ForgetPassword },
  { path: "/reset-password", element: ForgetPassword },
  {
    path: "/change-password",
    element: ProfileIndex,
  },

  {
    path: "/orders",
    element: OrdersIndex,
  },
  {
    path: "/cart",
    element: CarttIndex,
  },
  {
    path: "/about",
    element: ProfileIndex,
  },
  {
    path: "/payment",
    element: PaymentIndex,
  },
  {
    path: "/event-details/:id",
    element: EventIndex,
  },
  {
    path: "/verify-card/:parm",
    element: VerifyCardIndex,
  },
  {
    path: "/check-card",
    element: VerifyCardPpgesIndex,
  },
  {
    path: "*",
    element: HomeIndex,
  },
];
