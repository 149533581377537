import { createSlice } from "@reduxjs/toolkit";

const TeamSlice = createSlice({
  name: "teams",
  initialState: {
    teams: [],
    team: {},
  },
  reducers: {
    setTeams: (state, action) => {
      state.teams = action.payload;
    },
    setTeam: (state, action) => {
      state.team = action.payload;
    },
  },
});

export const { setTeams, setTeam } = TeamSlice.actions;

export default TeamSlice.reducer;
