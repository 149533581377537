import { createSlice } from "@reduxjs/toolkit";

const CartSlice = createSlice({
  name: "carts",
  initialState: {
    carts: [],
    cartMsg: { open: false, message: "" },
    selectedUserOrder: '',
    nextPage: "",
  },
  reducers: {
    setCarts: (state, action) => {
      state.carts = action.payload;
    },
    setCartMsg: (state, action) => {
      state.cartMsg = action.payload;
    },
    setUserOrder: (state, action) => {
      state.selectedUserOrder = action.payload;
    },
    setNextPage: (state, action) => {
      state.nextPage = action.payload;
    },
  },
});

export const { setCarts, setCartMsg, setUserOrder, setNextPage } =
  CartSlice.actions;

export default CartSlice.reducer;
