import { createSlice } from "@reduxjs/toolkit";

const SettingSlice = createSlice({
  name: "settings",
  initialState: {
    settings: {},
    whatsStatus: false,
    qrImage:''
  },
  reducers: {
    setSettings: (state, action) => {
      state.settings = action.payload;
    },
    setWhatsStatus: (state, action) => {
      state.whatsStatus = action.payload;
    },
    setQrImage: (state, action) => {
      state.qrImage = action.payload;
    },
  },
});

export const { setSettings, setWhatsStatus, setQrImage } = SettingSlice.actions;

export default SettingSlice.reducer;
