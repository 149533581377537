import { lazy } from "react";
import WithLazyComponent from "../shared/components/WithLazyComponent";
import DashboardIcon from "@mui/icons-material/Dashboard";
import i18next from "../i18n/config";
import GroupsIcon from '@mui/icons-material/Groups';
import StadiumIcon from '@mui/icons-material/Stadium';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import EventIcon from '@mui/icons-material/Event';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import SettingsIcon from '@mui/icons-material/Settings';
import BackupTableIcon from '@mui/icons-material/BackupTable';

const DashboardIndex = WithLazyComponent(
  lazy(() => import("./Dashboard/index"))
);
const CategoryIndex = WithLazyComponent(
  lazy(() => import("./Categories/index"))
);
const PlaygroundIndex = WithLazyComponent(
  lazy(() => import("./Playgrounds/index"))
);
const TeamIndex = WithLazyComponent(lazy(() => import("./Teams/index")));

const OrderIndex = WithLazyComponent(lazy(() => import("./Orders/index")));
const EventIndex = WithLazyComponent(lazy(() => import("./Events/index")));
const SettingIndex = WithLazyComponent(lazy(() => import("./Settings/index")));
const ReportIndex = WithLazyComponent(lazy(() => import("./Report/index")));

const AdminRoutes = [
  {
    path: "/admin/dashboard",
    icon: LeaderboardIcon,
    label: "dashboard",
    children: [
      { index: true, element: DashboardIndex },
      //   { path: "create", element: Create },
    ],
  },
  {
    path: "/admin/categories",
    icon: DashboardIcon,
    label: "categories",
    children: [
      { index: true, element: CategoryIndex },
      { path: "/admin/categories/create", element: CategoryIndex },
      { path: "/admin/categories/view/:id", element: CategoryIndex },
    ],
  },
  {
    path: "/admin/playgrounds",
    icon: StadiumIcon,
    label: "playgrounds",
    children: [
      { index: true, element: PlaygroundIndex },
      { path: "/admin/playgrounds/create", element: PlaygroundIndex },
      { path: "/admin/playgrounds/view/:id", element: PlaygroundIndex },
      { path: "/admin/playgrounds/view-tears/:id", element: PlaygroundIndex },
      { path: "/admin/playgrounds/view-tears-seats/:id", element: PlaygroundIndex },

    ],
  },
  {
    path: "/admin/teams",
    icon: GroupsIcon,
    label: "teams",
    children: [
      { index: true, element: TeamIndex },
      { path: "/admin/teams/create", element: TeamIndex },
      { path: "/admin/teams/view/:id", element: TeamIndex },
    ],
  },
  {
    path: "/admin/events",
    icon: EventIcon,
    label: "events",
    children: [
      { index: true, element: EventIndex },
      { path: "/admin/events/create", element: EventIndex },
      { path: "/admin/events/view/:id", element: EventIndex },
    ],
  },
  {
    path: "/admin/orders",
    icon: ReceiptLongIcon,
    label: "all_orders",
    children: [
      { index: true, element: OrderIndex },
      { path: "/admin/orders/create", element: OrderIndex },
      { path: "/admin/orders/view/:id", element: OrderIndex },
    ],
  },
  {
    path: "/admin/report",
    icon: BackupTableIcon,
    label: "report",
    children: [
      { index: true, element: ReportIndex },
      //   { path: "create", element: Create },
    ],
  },
  {
    path: "/admin/settings",
    icon: SettingsIcon,
    label: "settings",
    children: [
      { index: true, element: SettingIndex },
      //   { path: "create", element: Create },
    ],
  },


  // {
  //   path: "*",
  //   // element: HomeIndex,
  // },
];

export default AdminRoutes;
