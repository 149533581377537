import { configureStore } from "@reduxjs/toolkit";
import AuthSlice from "./Reducers/AuthSlice";
import CategorySlice from "./Reducers/CategorySlice";
import PlaygroundSlice from "./Reducers/PlaygroundSlice";
import TeamSlice from "./Reducers/TeamSlice";
import EventSlice from "./Reducers/EventSlice";
import OrderSlice from "./Reducers/OrderSlice";
import DashboardSlice from "./Reducers/DashboardSlice";
import CartSlice from "./Reducers/CartSlice";
import SettingSlice from "./Reducers/SettingSlice";
import ReportSlice from "./Reducers/ReportSlice";

const loadState = () => {
  try {
    const serializedState = localStorage.getItem("state");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (e) {
    return undefined;
  }
};
const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch (e) {
    // Ignore write errors;
  }
};
const preloadedState = loadState();
const store = configureStore({
  reducer: {
    auth: AuthSlice,
    categories: CategorySlice,
    playgrounds: PlaygroundSlice,
    teams: TeamSlice,
    events: EventSlice,
    orders: OrderSlice,
    dashboard: DashboardSlice,
    carts: CartSlice,
    settings: SettingSlice,
    report: ReportSlice,
  },
  preloadedState,
  // enhancers: [window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()],
});
export default store;
store.subscribe(() => {
  saveState(store.getState());
});
