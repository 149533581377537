import { createSlice } from "@reduxjs/toolkit";

const ReportSlice = createSlice({
  name: "report",
  initialState: {
    reports: [],
    collapseReports: [],
  },
  reducers: {
    setReports: (state, action) => {
      state.reports = action.payload;
    },
    setCollapseReports: (state, action) => {
      state.collapseReports = action.payload;
    },
  },
});

export const { setReports, setCollapseReports } = ReportSlice.actions;

export default ReportSlice.reducer;
